/* eslint-disable */

import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faAngellist } from "@fortawesome/free-brands-svg-icons/faAngellist";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faTelegram } from "@fortawesome/free-brands-svg-icons/faTelegram";
import { faDiscord } from "@fortawesome/free-brands-svg-icons/faDiscord";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";

// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: "https://github.com/0418v",
    label: "Github",
    icon: faGithub,
  },
  // {
  //   link: "https://www.linkedin.com/in/vincent-lee-bb3816323/",
  //   label: "LinkedIn",
  //   icon: faLinkedinIn,
  // },
  // {
  //   link: "https://angel.co/michael-d-angelo",
  //   label: "Angel List",
  //   icon: faAngellist,
  // },
  {
    link: "https://twitter.com/Vincentlee234/",
    label: "Twitter",
    icon: faTwitter,
  },
  {
    link: "https://t.me/Leon0418v",
    label: "Telegram",
    icon: faTelegram,
  },
  // {
  //   link: "https://twitter.com/dangelosaurus",
  //   label: "Discord",
  //   icon: faDiscord,
  // },
  {
    link: "mailto:lee0418v@gmail.com",
    label: "Email",
    icon: faEnvelope,
  },
];

export default data;
